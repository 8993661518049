<template>
  <v-snackbar bottom right :value="updateExists" :timeout="-1" color="success">
    E' disponibile un aggiornamento dell'app
    <v-btn text @click="refreshApp">
      Aggiorna
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  name: 'Update',
  data: () => ({
    refreshing: false,
    registration: null,
    updateExists: false,
  }),

  created() {
    document.addEventListener('swUpdated', this.updateAvailable, { once: true })
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      window.location.reload()
    })
  },

  methods: {
    updateAvailable(event) {
      console.info('swUpdated notice')
      this.registration = event.detail
      this.updateExists = true
    },

    // Called when the user accepts the update
    refreshApp() {
      this.updateExists = false
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return

      // send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },
  },
}
</script>

<style scoped></style>
