<template>
  <v-snackbar :value="active" @input="onInput">
    {{ text }}

    <template v-slot:action="{ attrs }">
      <v-btn color="pink" text v-bind="attrs" @click="hideMessage">
        Chiudi
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'Notifications',
  computed: {
    ...mapState('notifications', {
      text: (state) => state.message.text,
      active: (state) => state.message.active,
    }),
  },
  methods: {
    onInput(val) {
      if (!val) {
        this.hideMessage()
      }
    },
    ...mapMutations('notifications', {
      hideMessage: 'UNSET_MESSAGE',
    }),
  },
}
</script>
