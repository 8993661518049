<script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main.vue'
import Notifications from '@components/common/Notifications.vue'
import Update from '@components/common/Update.vue'

export default {
  page: {
    title: 'Home',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: { Update, Notifications, Layout },
  methods: {
    onIdle() {
      this.$router.push({ name: 'logout' })
    },
  },
}
</script>

<template>
  <Layout>
    <router-view :key="$route.path" class="px-md-10 py-md-6"></router-view>
    <Notifications />
    <Update />
    <v-idle v-show="false" :duration="1800" @idle="onIdle" />
  </Layout>
</template>
